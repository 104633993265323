import { useRouter } from "next/router";
import Lottie from "react-lottie";
import { PRIVATE_ROUTES } from "routes/routes";
import { Button } from "rsuite";
import thinkingAi from "utils/lottie/thinking.json";

import useTranslation from "next-translate/useTranslation";

const loaderLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: thinkingAi,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Error404 = () => {
  const router = useRouter();
  const { t } = useTranslation("common");

  return (
    <div className="flex h-full flex-col items-center justify-center gap-2">
      <div className="animate__float">
        <Lottie
          isClickToPauseDisabled
          options={loaderLottieOptions}
          height={330}
          width={330}
        />
      </div>
      <div className="text-center">
        <span className="mb-3 text-4xl font-bold text-gray-800 dark:text-gray-200">
          {t("Error 404")}
        </span>

        <p className="dark:text-white">
          {t("Esta ruta no existe o no tienes permisos para acceder a ella")}
        </p>
      </div>

      <Button
        appearance="primary"
        size="lg"
        className="rounded-full"
        onClick={() => router.push(PRIVATE_ROUTES.explore.path)}
      >
        {t("Volver al home")}
      </Button>
    </div>
  );
};

export default Error404;
